<template>
  <div>
    <Header />
    <div
      v-loading="appLoading"
      class="app"
    >
      <keep-alive v-if="getRoute">
        <router-view />
      </keep-alive>
      <router-view v-else />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@components/Header";
import Footer from "@components/Footer";
import { mapGetters } from "vuex";
import Vue from 'vue';
import { Toast,Loading } from 'vant';

Vue.use(Loading);

Vue.use(Toast);

export default {
  name: 'App',
  data () {
    return {

    }
  },
  computed: {
    getRoute () {
      return this.$route.path == '/paySuccess'
    },
    ...mapGetters(["maskShow","appLoading"])
  },
  watch: {
    getRoute (val) {
    },
    maskShow () {
      if (this.maskShow) {
        // Toast('抢购人数太多了，请稍后再试～');
      }
    }
  },
  components: {
    Header,
    Footer
  },
}
</script>
<style lang="less" scoped>
/deep/ .el-loading-spinner {
  top: 45vh;
}
</style>


// 开发环境配置
const devConfig = {
  // server: 'https://fox.genimeta.com',
  server: 'https://mapi-qa.gcfuture.cn',
  locationHref:'https://tiger.gcfuture.cn/#/',
  ipfsBrowerHref: 'https://ipfs.gcfuture.cn',
  winxinOpenUrl: 'https://tiger.gcfuture.cn',
  winxinPaySecret: 'ef115e4d29ebef974bb1d849c699615a',
  weixinAppId: 'wxc93fc199ea0d793d',
  appId: 'rk3y5lEY',
  appSecret: '54e29bdd5119a4fe6fc6d86470a805b9853a1b2d',
  explorerUrl: 'https://explorer.gcfuture.cn/#/blocks/',
  explorerUrlBase: 'https://explorer.gcfuture.cn/#/',
  mediaType: {
    '1005': 'img', // 图画
    '1006': 'audio', // 音频
    '1007': 'video', // 视屏
    '1008': '3d', // 3d
    '1010': 'img', // 图画
    '1011': 'audio', // 音频
    '1012': 'video', // 视屏
    '1013': '3d', // 3d
  }
}

// 生产环境配置
const proConfig = {
  server: 'https://meta-art.genimous.com',
  locationHref:'https://meta-h5.genimous.com/#/',
  ipfsBrowerHref: 'https://ipfs.gcfuture.cn',
  winxinOpenUrl: 'https://meta-h5.genimous.com',
  winxinPaySecret: 'ef115e4d29ebef974bb1d849c699615a',
  weixinAppId: 'wxc93fc199ea0d793d',
  appId: 'rk3y5lEY',
  appSecret: '54e29bdd5119a4fe6fc6d86470a805b9853a1b2d',
  explorerUrl: 'https://explorer.gcfuture.cn/#/blocks/',
  explorerUrlBase: 'https://explorer.gcfuture.cn/#/',
  mediaType: {
    '1005': 'img', // 图画
    '1006': 'audio', // 音频
    '1007': 'video', // 视屏
    '1008': '3d', // 3d
    '1010': 'img', // 图画
    '1011': 'audio', // 音频
    '1012': 'video', // 视屏
    '1013': '3d', // 3d
  }
}

let config = process.env.NODE_ENV == "development" ? devConfig : proConfig

module.exports = config
export default {
  maskShow: state => state.app.maskShow,
  appLoading: state => state.app.appLoading,
  headerShowBg: state => state.app.headerShowBg,
  isAuth: state => state.app.isAuth,
  isSetPwd:  state => state.app.isSetPwd,
  userOpenId:   state => state.app.userOpenId,
  searchCollectionName:   state => state.app.searchCollectionName,
  isSetinvitation:  state => state.app.isSetinvitation,
  showMaterialNorEnoughTips: state => state.app.showMaterialNorEnoughTips,
  isSearch:  state => state.app.isSearch,
  confirmDonationSelect:  state => state.app.confirmDonationSelect,
  selectCollectionTab:   state => state.app.selectCollectionTab,
  adressManageEdit: state => state.app.adressManageEdit,
};

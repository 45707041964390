import Vue from 'vue'
import { Toast } from 'vant';
// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'vant/lib/toast/style'
import App from './App.vue'
import * as filters from './filters'
import router from './router'
import store from "./store";
import VueAwesomeSwiper from 'vue-awesome-swiper'

import 'swiper/dist/css/swiper.css'

Vue.use(VueAwesomeSwiper)
import moment from "moment"
Vue.prototype.$moment = moment;

Vue.use(Toast);

// 导入样式
import './assets/css/index.less'

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// 挂载util
import * as Utils from './utils/util'
Vue.prototype.$utils = Utils
import {Loading} from 'element-ui'
Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
// Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

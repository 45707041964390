// Imports
import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'// progress bar style
import Cookies from 'js-cookie'
import store from "../store";
import { log } from 'video.js'

Vue.use(Router)

const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const whiteList = ['/','/gashapon','/goodDetails','/myCollection','/my','/login','/gl','/share', '/register', '/download', '/agreementPolicy', '/registerShare', '/lottery', '/lotteryRule' ,'/contractCode', '/test', '/wishingWellList','/wishingWellDetails']// no redirect whitelist

const router = new Router({
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },

  routes: [
    {
      path: '/',
      component: () => import('@/views/Index.vue'),
      children: [
        {
          path: '',
          name: '商城',
          component: () => import('@/views/Index.vue'),
        }
      ],
    },
    {
      path: '/my',
      name: '我的',
      component: () => import('@/views/My.vue'),
    },
    {
      path: '/myDetail',
      name: '个人信息',
      component: () => import('@/views/MyDetails.vue'),
    },
    {
      path: '/order',
      name: '购买订单',
      component: () => import('@/views/Order.vue'),
    },
    {
      path: '/donationOrder',
      name: '赠送订单',
      component: () => import('@/views/DonationOrder.vue'),
    },
    {
      path: '/orderDetail',
      name: '订单详情',
      component: () => import('@/views/OrderDetails.vue'),
    },
    {
      path: '/collection',
      name: '藏品',
      component: () => import('@/views/Collection.vue'),
    },
    {
      path: '/buyDetail',
      name: '详情',
      component: () => import('@/views/BuyDetail.vue'),
    },
    {
      path: '/paySuccess',
      name: '支付成功',
      component: () => import('@/views/PaySuccess.vue'),
    },
    {
      path: '/goodDetails',
      name: '详情',
      component: () => import('@/views/GoodDetails.vue'),
    },
    {
      path: '/share',
      name: '分享',
      component: () => import('@/views/Share.vue'),
    },
    {
      path: '/login',
      name: '登陆',
      component: () => import('@/views/Login.vue'),
    },
    {
      path: '/gl',
      name: '登陆',
      component: () => import('@/views/GiftLogin.vue'),
    },
    {
      path: '/register',
      name: '注册',
      component: () => import('@/views/Register.vue'),
    },
    {
      path: '/download',
      name: '下载',
      component: () => import('@/views/Download.vue'),
    },
    {
      path: '/agreementPolicy',
      name: '用户协议',
      component: () => import('@/views/AgreementPolicy.vue'),
    },
    {
      path: '/lottery',
      name: '抽奖界面',
      component: () => import('@/views/Lottery.vue'),
    },
    {
      path: '/lotteryRule',
      name: '抽奖协议',
      component: () => import('@/views/LotteryRule.vue'),
    },
    {
      path: '/contractCode',
      name: '抽奖代码',
      component: () => import('@/views/ContractCode.vue'),
    },
    {
      path: '/certification',
      name: '实名认证',
      component: () => import('@/views/Certification.vue'),
    },
    {
      path: '/test',
      name: '测试页面',
      component: () => import('@/views/Test.vue'),
    },
    {
      path: '/myCollection',
      name: '藏品',
      component: () => import('@/views/MyCollection.vue'),
    },
    {
      path: '/modifyPhone',
      name: '换绑手机号',
      component: () => import('@/views/ModifyPhone.vue'),
    },
    {
      path: '/set',
      name: '设置',
      component: () => import('@/views/Set.vue'),
    },
    {
      path: '/resetPwd',
      name: '资产密码设置',
      component: () => import('@/views/ResetPwd.vue'),
    },
    {
      path: '/donation',
      name: '转赠',
      component: () => import('@/views/Donation.vue'),
    },
    {
      path: '/compositing',
      name: '合成',
      component: () => import('@/views/Compositing.vue'),
    },
    {
      path: '/compositeSelectMaterial',
      name: '合成材料',
      component: () => import('@/views/CompositeSelectMaterial.vue'),
    },
    {
      path: '/compositeDetail',
      name: '合成详情',
      component: () => import('@/views/CompositeDetail.vue'),
    },
    {
      path: '/compositeList',
      name: '合成',
      component: () => import('@/views/CompositeList.vue'),
    },
    {
      path: '/compositeRecord',
      name: '合成记录',
      component: () => import('@/views/CompositeRecord.vue'),
    },
    {
      path: '/compositeRecordDetail',
      name: '详情',
      component: () => import('@/views/CompositeRecordDetail.vue'),
    },
    {
      path: '/compositeOrder',
      name: '合成订单',
      component: () => import('@/views/CompositeOrder.vue'),
    },
    {
      path: '/forgetPwd',
      name: '忘记密码',
      component: () => import('@/views/ForgetPwd.vue'),
    },
    {
      path: '/modifyPhone',
      name: '忘记密码',
      component: () => import('@/views/ModifyPhone.vue'),
    },
    {
      path: '/orderSelect',
      name: '订单',
      component: () => import('@/views/OrderSelect.vue'),
    },
    {
      path: '/aboutUs',
      name: '联系我们',
      component: () => import('@/views/AboutUs.vue'),
    },
    {
      path: '/invitationFriends',
      name: '邀请好友',
      component: () => import('@/views/InvitationFriends.vue'),
    },
    {
      path: '/lotteryList',
      name: '中签名单',
      component: () => import('@/views/LotteryList.vue'),
    },
    {
      path: '/explore',
      name: '探索',
      component: () => import('@/views/Explore.vue'),
    },
    {
      path: '/management',
      name: '馆藏详情',
      component: () => import('@/views/Management.vue'),
    },
    {
      path: '/artistDetails',
      name: '艺术家详情',
      component: () => import('@/views/ArtistDetails.vue'),
    },
    {
      path: '/seriesCollectionDetails',
      name: '艺术家详情',
      component: () => import('@/views/SeriesCollectionDetails.vue')
    },
    {
      path: '/seriesCollection',
      name: '系列藏品',
      component: () => import('@/views/seriesCollection.vue'),
    },
    {
      path: '/activityDescription',
      name: '说明',
      component: () => import('@/views/ActivityDescription.vue'),
    },
    {
      path: '/myInvitate',
      name: '邀请记录',
      component: () => import('@/views/MyInvitate.vue'),
    },
    {
      path: '/topList',
      name: '榜单',
      component: () => import('@/views/TopList.vue'),
    },
    {
      path: '/about',
      name: '关于',
      component: () => import('@/views/About.vue'),
    },
    {
      path: '/donationSelect',
      name: '选择藏品',
      component: () => import('@/views/DonationSelect.vue'),
    },
    {
      path: '/logOff',
      name: '注销账户',
      component: () => import('@/views/LogOff.vue'),
    },
    {
      path: '/logOffPolicy',
      name: '注销协议',
      component: () => import('@/views/LogOffPolicy.vue'),
    },
    {
      path: '/openBlindBoxRecord',
      name: '拆盒记录',
      component: () => import('@/views/OpenBlindBoxRecord.vue'),
    },
    {
      path: '/blindDetails',
      name: '拆盒详情',
      component: () => import('@/views/BlindDetails.vue'),
    },
    {
      path: '/blindOrder',
      name: '盲盒订单',
      component: () => import('@/views/BlindOrder.vue'),
    },
    {
      path: '/adressManage',
      name: '收货地址',
      component: () => import('@/views/AdressManage.vue'),
    },
    {
      path: '/addAdress',
      name: '新增收货地址',
      component: () => import('@/views/AddAdress.vue'),
    },
    {
      path: '/exchangeOrder',
      name: '兑换订单',
      component: () => import('@/views/exchangeOrder.vue'),
    },
    {
      path: '/conversion',
      name: '兑换',
      component: () => import('@/views/Conversion.vue'),
    },
    {
      path: '/conversionCollection',
      name: '选择藏品',
      component: () => import('@/views/ConversionCollection.vue'),
    },
    {
      path: '/exchangeDetails',
      name: '订单详情',
      component: () => import('@/views/exchangeDetails.vue'),
    },
    {
      path: '/logistics',
      name: '查看物流',
      component: () => import('@/views/Logistics.vue'),
    },
    {
      path: '/modelDetails',
      name: '模型详情',
      component: () => import('@/views/ModelDetails.vue'),
    },
    {
      path: '/giftReceive',
      name: '领取结果',
      component: () => import('@/views/GiftReceive.vue'),
    },
    {
      path: '/integral',
      name: '积分',
      component: () => import('@/views/integral/Integral.vue'),
    },
    {
      path: '/integralDetails',
      name: '积分说明',
      component: () => import('@/views/integral/integralDetails.vue'),
    },
    {
      path: '/gashapon',
      name: '抽奖活动',
      component: () => import('@/views/capsuleToys/Gashapon.vue'),
    },
    {
      path: '/gashaponRecord',
      name: '抽奖记录',
      component: () => import('@/views/capsuleToys/GashaponRecord.vue'),
    },
    {
      path: '/integralExchange',
      name: '积分兑换',
      component: () => import('@/views/capsuleToys/IntegralExchange.vue'),
    },
    {
      path: '/collectionExchange',
      name: '藏品兑换',
      component: () => import('@/views/capsuleToys/CollectionExchange.vue'),
    },
    {
      path: '/collectionExchangeDetail',
      name: '藏品兑换详情',
      component: () => import('@/views/capsuleToys/CollectionExchangeDetail.vue'),
    },
    {
      path: '/collectionSkuNoExchange',
      name: '更换藏品',
      component: () => import('@/views/capsuleToys/CollectionSkuNoExchange.vue'),
    },
    {
      path: '/wishingWellList',
      name: '许愿池场次列表',
      component: () => import('@/views/wishingWell/WishingWellList.vue'),
    },
    {
      path: '/wishingWellDetails',
      name: '许愿池活动',
      component: () => import('@/views/wishingWell/WishingWellDetails.vue'),
    },
    {
      path: '/recordList',
      name: '参与记录',
      component: () => import('@/views/wishingWell/RecordList.vue'),
    },
    {
      path: '/rewordList',
      name: '中奖记录',
      component: () => import('@/views/wishingWell/RewordList.vue'),
    },
    {
      path: '/coupon',
      name: '优惠券',
      component: () => import('@/views/my/Coupon.vue'),
    },
    
    
    
    
  ],
})

router.beforeEach((to, from, next) => {
  
  if (!localStorage.getItem('fromUrlList')) {
    localStorage.setItem('fromUrlList',JSON.stringify([]))
  }

  if (to.path == '/' || to.path == '/my' || to.path == '/myCollection' || to.path == '/explore') {
    localStorage.setItem('fromUrlList',JSON.stringify([]))
  } else if (from.name == null && from.path == '/') {
    // 刷新页面
  } else {
    let arr1 = JSON.parse(localStorage.getItem('fromUrlList'))
    arr1.push(from.fullPath)
    localStorage.setItem('fromUrlList',JSON.stringify(arr1))
  }
  
  if (to.meta.title) {
    document.title = to.meta.title + ' - ' + Config.title
  }
  if (to.path !== '/buyDetail' && to.path !== '/my') {
    store.commit('SHOW_APPLOADING')  
  }
  // NProgress.start()
  if(to.query.token) {
    Cookies.set('Login',to.query.token,{ expires: 7 })
    Cookies.set('Device',to.query.device,{ expires: 7 })  
  }
  let newQuery = JSON.parse(JSON.stringify(to.query)) // 深拷贝
  delete newQuery.token
  delete newQuery.device
  // 如果有引入 lodash, 可以写成: let newQuery = _.omit(this.$route.query, 'code')
  if (Cookies.get('Login')) {
    if(localStorage.getItem('loginToGashapon')) {
      let router = localStorage.getItem('loginToGashapon')
      localStorage.removeItem('loginToGashapon')
      next( {path: router}) // 否则全部重定向到登录页
      
      NProgress.done()
    }
    if(localStorage.getItem('loginToWishing')) {
      let router = localStorage.getItem('loginToWishing')
      localStorage.removeItem('loginToWishing')
      next( {path: router}) // 否则全部重定向到登录页
      
      NProgress.done()
    }
    
    if (to.query.token) {
      next({ path: to.path,query: newQuery })
      NProgress.done()
    }
    // 已登录且要跳转的页面是登录页
    if (to.path === '/login') {
      next({ path: '/',query: newQuery })
      NProgress.done()
    }
    
    if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
      document.title = 'Meta彼岸'
    }
    next()
    // NProgress.done()
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
      document.title = 'Meta彼岸'
      // NProgress.done()
      next()
    } else {
      next( {path: `/login?redirect=${to.path}`, query: newQuery}) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

export default router

// 金额格式化过滤器
export function monetyAns (value) {
  if (value == '- -') return '- -'
  if (!value) return '0'
  var isFu = false
  // 负数单独处理
  if (Math.sign(value) === -1) {
    isFu = true
    value = -value
  }
  var intPart = Number(value) - Number(value) % 1 // 获取整数部分（这里是windy93的方法）
  var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') //将整数部分逢三一断
  var floatPart = '.00' // 预定义小数部分
  var value2Array = value.toString().split('.')
  //= 2表示数据有小数位
  if (value2Array.length === 2) {
    floatPart = value2Array[1].toString() // 拿到小数部分
    if (floatPart.length > 2) {
      var a = '.' + floatPart
      if (parseFloat(a).toFixed(2) === '1.00') {
        return (isFu ? '-' : '') + (parseInt(intPartFormat) + 1)
      } else {
        return (isFu ? '-' : '') + intPartFormat + parseFloat(a).toFixed(2).substr(1, 4)
      }
    } else if (floatPart.length === 1) { // 补0,实际上用不着
      return (isFu ? '-' : '') + intPartFormat
    } else {
      return (isFu ? '-' : '') + intPartFormat
    }
  } else {
    return (isFu ? '-' : '') + intPartFormat
  }
}
// 计算文件大小
export function calculateSize (value) {
  if (!value) {
    return null
  }else if (parseInt(value/1024) < 1024) {
    return parseInt(value/1024) + 'KB'
  } else if (parseInt(value/1024/1024) < 1024) {
    return (value/1024/1024).toFixed(2) + 'MB'
  }
}

// 金额数字转大写过滤
export function toChies (amount) {
  // 形参
  // 汉字的数字
  const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
  // 基本单位
  const cnIntRadice = ['', '拾', '佰', '仟']
  // 对应整数部分扩展单位
  const cnIntUnits = ['', '万', '亿', '兆']
  // 对应小数部分单位
  const cnDecUnits = ['角', '分', '毫', '厘']
  // 整数金额时后面跟的字符
  const cnInteger = '整'
  // 整型完以后的单位
  const cnIntLast = '元'
  // 最大处理的数字
  const maxNum = 9999999999999999.99
  // 金额整数部分
  let integerNum
  // 金额小数部分
  let decimalNum
  // 输出的中文金额字符串
  let chineseStr = ''
  // 分离金额后用的数组，预定义
  let parts
  if (amount === '') { return '' }
  amount = parseFloat(amount)
  if (amount >= maxNum) {
    // 超出最大处理数字
    return ''
  }
  if (amount === 0) {
    chineseStr = cnNums[0] + cnIntLast + '&#160;' + cnInteger
    return chineseStr
  }
  // 转换为字符串
  amount = amount.toString()
    if (amount.indexOf('.') === -1) {
      integerNum = amount
      decimalNum = ''
    } else {
      parts = amount.split('.')
      integerNum = parts[0]
      decimalNum = parts[1].substr(0, 4)
    }
    // 获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
      let zeroCount = 0
      const IntLen = integerNum.length
      for (let i = 0; i < IntLen; i++) {
        const n = integerNum.substr(i, 1)
        const p = IntLen - i - 1
        const q = p / 4
        const m = p % 4
        if (n === '0') {
          zeroCount++
        } else {
          if (zeroCount > 0) {
            chineseStr += cnNums[0]
          }
          // 归零
          zeroCount = 0
          chineseStr += cnNums[parseInt(n, 10)] + cnIntRadice[m]
        }
        if (m === 0 && zeroCount < 4) {
          chineseStr += cnIntUnits[q]
        }
      }
      chineseStr += cnIntLast
    }
    // 小数部分
    if (decimalNum !== '') {
      const decLen = decimalNum.length
      for (let i = 0; i < decLen; i++) {
      const n = decimalNum.substr(i, 1)
      if (n !== '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if (chineseStr === '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (decimalNum === '') {
    chineseStr += cnInteger
  }
  return chineseStr
}

// 判断所选时间和当前时间的差多少天
export function getBeforeDate (time) {
  var iDays
  if (time === '') {
    iDays = ''
  } else {
    var date2 = new Date()
    var date1 = new Date(Date.parse(time.replace(/-/g, '/')))
    if (date2.getTime() > date1.getTime()) {
      return iDays = '--'
    }
    iDays =(parseInt(Math.abs(date2.getTime() - date1.getTime()) / 1000 / 60 / 60 / 24) + 1)
  }
  return iDays
}

// 数字千分位
function formatThousand (str) {
  let arr = str.split('').reverse()
  let list = []
  arr.map((i,j) => {
    list.push(i)
    if ((j + 1) % 3 === 0) {
      list.push(',')
    }
  })
  let res = list.reverse()
  if (res[0] === ',') {
    res.shift()
  }
  if (res[res.length - 1] === ',') {
    res.pop()
  }
  return res.join('')
}

export function moneyFormat (no) {
  if (!no) return '0.00'
  let str = Number(no)
    .toFixed(2)
    .toString()
  let start = str.split('.')[0]
  let end = str.split('.')[1]
  return formatThousand(start) + '.' + end
}

// 返回日期字符串一律显示10位
export function timeStrMaxLengthTen (str) {
  if (!str) return ''
  return str.substring(0, 10)
}

// 时间格式转换 
export function formatDate(dateTime) {
  var fmt = 'yyyy/MM/dd hh:mm:ss'
  var dateTime = new Date(dateTime);
  var o = {
      "M+": dateTime.getMonth() + 1,               //月份   
      "d+": dateTime.getDate(),                    //日   
      "h+": dateTime.getHours(),                   //小时   
      "m+": dateTime.getMinutes(),                 //分   
      "s+": dateTime.getSeconds(),                 //秒   
      "q+": Math.floor((dateTime.getMonth() + 3) / 3), //季度   
      "S": dateTime.getMilliseconds()             //毫秒   
  };

  if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (dateTime.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      }
  }
  return fmt;
}


// 值为空的转为/
export function nullToVirgule (str) {
  return str ? str : '/'
}

// 时间格式转换/
export function timeFormate (time) {
  let str = String(time)
  return str.substring(0,4) + '.' + str.substring(5,7) + '.' + str.substring(8,10)
}

// 姓名隐藏
export function userNameHide (str) {
  const username = String(str)
  return username.length == 2 ? username.substring(0,1) + '*' : username.length == 3 ? username.substring(0,1) + '*' + username.substring(2,): username.length == 4 ? username.substring(0,1) + '**' + username.substring(3,): username.substring(0,1) + '**' + username.substring(3,)
}

// 身份证号隐藏
export function idCardHide (str) {
  const idCard = String(str)
  return idCard.substring(0,6) + '****' + idCard.substring(idCard.length-4,)
}

// 手机号隐藏
export function phoneHide (str) {
  const phone = String(str)
  return phone.substring(0,3) + '****' + idCard.substring(idCard.length-4,)
}
const state = {
  maskShow: false,
  appLoading: false,
  headerShowBg: false,
  isAuth: false,
  isSetPwd: false,
  userOpenId: '',
  isSetinvitation: true,
  showMaterialNorEnoughTips: false,
  searchCollectionName: '',
  isSearch: false,
  confirmDonationSelect: false,
  selectCollectionTab: '1',
  adressManageEdit: false
};

const mutations = {
  SET_SELECTCOLLECTIONTAB (state, tab) {
    state.selectCollectionTab = tab
  },
  SET_ADREESSMANAGEEDIT (state, edit) {
    state.adressManageEdit = edit
  },
  SHOW_SMNE (state) {
    state.showMaterialNorEnoughTips = true;
  },
  HIDE_SMNE (state) {
    state.showMaterialNorEnoughTips = false;
  },
  SHOW_DONATIONSELECT (state) {
    state.confirmDonationSelect = true;
  },
  HIDE_DONATIONSELECT (state) {
    state.confirmDonationSelect = false;
  },
  SHOW_SEARCH (state) {
    state.isSearch = true;
  },
  HIDE_SEARCH (state) {
    state.isSearch = false;
  },
  SHOW_HEADER (state) {
    state.headerShowBg = true;
  },
  HIDE_HEADER (state) {
    state.headerShowBg = false;
  },
  SHOW_MASKSHOW(state) {
    state.maskShow = true;
  },
  SET_SEARCHCOLLECTIONNAME (state, name) {
    state.searchCollectionName = name
  },
  SET_USEROPENID (state, id) {
    state.userOpenId = id
  },
  HIDE_MASKSHOW(state) {
    state.maskShow = false;
  },
  SHOW_ISAUTH(state) {
    state.isAuth = true;
  },
  HIDE_ISAUTH(state) {
    state.isAuth = false;
  },
  SHOW_ISSETPWD(state) {
    state.isSetPwd = true;
  },
  HIDE_ISSETPWD(state) {
    state.isSetPwd = false;
  },
  SHOW_ISSETINVITATION(state) {
    state.isSetinvitation = true;
  },
  HIDE_ISSETINVITATION(state) {
    state.isSetinvitation = false;
  },
  SHOW_APPLOADING(state) {
    state.appLoading = true;
  },
  HIDE_APPLOADING(state) {
    state.appLoading = false;
  },
};


export default {
  state,
  mutations
};

<template>
  <div
    v-if="showFooter && $route.query.isBanner !== 'recommend'"
    class="footer-container row-center"
    :style="$route.path == '/goodDetails' || $route.path == '/compositeDetail' ? 'height: 60px !important' : ''"
  >
    <div
      v-if="showAuth"
      class="certification-bottom row-between"
      :style="$route.path == '/goodDetails' || ($route.path == '/compositeDetail' && !this.showMaterialNorEnoughTips)? 'bottom: 60px !important' : $route.path == '/compositeDetail' && this.showMaterialNorEnoughTips ?  'bottom: 78px !important' : ''"
    >
      <div class="row-center">
        <img src="../assets/icon/id-card.png" />
        <p>实名认证之后才能购买数字藏品</p>
      </div>
      <button @click="goCertification">去实名</button>
    </div>
    <div
      v-if="$route.path !== '/goodDetails' && $route.path !== '/compositeDetail'"
      @click="goNext('/', 0)"
      class="footer-item col-center"
    >
      <img
        v-if="selectIndex == 0"
        class="footer-item-icon1-select"
        :src="footer[0].iconSelect"
      />
      <img
        v-else
        class="footer-item-icon1"
        :src="footer[0].icon"
      />
      <p
        v-if="selectIndex == 0"
        class="footer-item-name-select"
      >{{  footer[0].name }}</p>
      <p
        v-else
        class="footer-item-name"
      >{{  footer[0].name }}</p>
    </div>
    <div
      v-if="$route.path !== '/goodDetails' && $route.path !== '/compositeDetail'"
      @click="goNext('/explore', 1)"
      class="footer-item col-center"
    >
      <img
        v-if="selectIndex == 1"
        class="footer-item-icon1-select"
        :src="footer[1].iconSelect"
      />
      <img
        v-else
        class="footer-item-icon4"
        :src="footer[1].icon"
      />
      <p
        v-if="selectIndex == 1"
        class="footer-item-name-select"
      >{{  footer[1].name }}</p>
      <p
        v-else
        class="footer-item-name"
      >{{  footer[1].name }}</p>
    </div>
    <div
      v-if="$route.path !== '/goodDetails' && $route.path !== '/compositeDetail'"
      @click="goNext('/myCollection',2)"
      class="footer-item col-center"
    >
      <img
        v-if="selectIndex == 2"
        class="footer-item-icon2-select"
        :src="footer[2].iconSelect"
      />
      <img
        v-else
        class="footer-item-icon2"
        :src="footer[2].icon"
      />
      <p
        v-if="selectIndex == 2"
        class="footer-item-name-select"
      >{{  footer[2].name }}</p>
      <p
        v-else
        class="footer-item-name"
      >{{  footer[2].name }}</p>
    </div>
    <div
      v-if="$route.path !== '/goodDetails' && $route.path !== '/compositeDetail'"
      @click="goNext('/my', 3)"
      class="footer-item col-center"
    >
      <img
        v-if="selectIndex ==  3"
        class="footer-item-icon3-select "
        :src="footer[3].iconSelect"
      />
      <img
        v-else
        class="footer-item-icon3"
        :src="footer[3].icon"
      />
      <p
        v-if="selectIndex == 3"
        class="footer-item-name-select"
      >{{  footer[3].name }}</p>
      <p
        v-else
        class="footer-item-name"
      >{{  footer[3].name }}</p>
    </div>
    <el-dialog
      :visible.sync="browerOpenVisible"
      @confirm="browerOpenVisible = false"
      class="donation-dialog"
      center
    >
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="donation-error-title"
      >请前往手机端完成实名认证</p>
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <div
          @click="browerOpenVisible = false"
          class="donation-button row-center"
        >确定</div>
      </span>
    </el-dialog>
  </div>

</template>
<script>
// import api from '../api/index-client'
import { mapGetters } from "vuex";
import Cookies from 'js-cookie'
import Vue from 'vue';
import { Button,Dialog } from 'element-ui';
Vue.use(Button)
Vue.use(Dialog)
export default {
  data () {
    return {
      browerOpenVisible: false,
      footer: [
        {
          icon: require('../assets/icon/footer/home.png'),
          iconSelect: require('../assets/icon/footer/home-select.png'),
          name: '首页'
        },
        {
          icon: require('../assets/icon/footer/explore.png'),
          iconSelect: require('../assets/icon/footer/explore-select.png'),
          name: '探索'
        },
        {
          icon: require('../assets/icon/footer/collection.png'),
          iconSelect: require('../assets/icon/footer/collection-select.png'),
          name: '藏品'
        },
        {
          icon: require('../assets/icon/footer/my.png'),
          iconSelect: require('../assets/icon/footer/my-select.png'),
          name: '我的'
        },
      ],
      selectIndex: 0,
    }
  },
  computed: {
    showAuth () {
      return (this.$route.path == '/' && this.isAuth && Cookies.get('Login')) ||
        (this.$route.path == '/goodDetails' && this.isAuth && Cookies.get('Login')) ||
        (this.$route.path == '/compositeDetail' && this.isAuth && Cookies.get('Login'))
    },
    showFooter () {
      return this.$route.path == '/' || this.$route.path == '/myCollection' || this.$route.path == '/my' || this.$route.path == '/goodDetails' || this.$route.path == '/explore' || this.$route.path == '/compositeDetail'
    },
    ...mapGetters(["isAuth","showMaterialNorEnoughTips"]),
  },
  watch: {
    $route (to,from) {

      if (this.$route.path == '/' || this.$route.path == '/goodDetails' || this.$route.path == '/buyDetail' || this.$route.path == '/paySuccess' || this.$route.path == 'goodDetails' || this.$route.path == '/share') {
        this.selectIndex = 0
      } else if (this.$route.path == '/explore') {
        this.selectIndex = 1
      } else if (this.$route.path == '/collection' || this.$route.path == '/myCollection') {
        this.selectIndex = 2
      } else if (this.$route.path == '/my' || this.$route.path == '/myDetail' || this.$route.path == '/order' || this.$route.path == '/donationOrder' || this.$route.path == '/orderDetail' || this.$route.path == '/set' || this.$route.path == '/resetPwd' || this.$route.path == '/forgetPwd') {
        this.selectIndex = 3
      }
    }
  },
  methods: {
    goCertification () {
      if (Cookies.get('Device') == 'pico') {
        this.browerOpenVisible = true
      } else {
        this.$router.push("/certification")
      }

    },
    goNext (e,index) {
      this.selectIndex = index
      this.$router.push(e)
    }
  },
}
</script>

<style lang="less">
.footer-container {
  position: fixed;
  width: 100%;
  height: 3.125rem;
  bottom: 0;
  left: 0;
  background: #080808;
  z-index: 1998;
  .certification-bottom {
    position: fixed;
    bottom: 3.125rem;
    width: 100%;
    height: 3.125rem;
    background: rgba(48, 48, 48, 0.95);
    img {
      width: 1.625rem;
      height: auto;
      margin: 0 0.4375rem 0 1.1875rem;
    }
    p {
      font-size: 0.875rem;
      font-family: lantingheiweight;
      font-weight: normal;
      color: #ffffff;
      line-height: 1.1875rem;
      letter-spacing: 0.0625rem;
    }
    button {
      width: 4.6875rem;
      height: 1.875rem;
      background: #f3cdb6;
      border-radius: 0.25rem;
      font-size: 14px;
      font-family: lantingheiweight;
      font-weight: normal;
      color: #090a0a;
      line-height: 19px;
      letter-spacing: 1px;
      margin-right: 1.5625rem;
    }
  }
  .footer-item {
    width: 33%;
    height: 3.125rem;
    position: relative;
  }
  .footer-item-icon1 {
    width: 1.1875rem;
    height: 1.25rem;
    position: absolute;
    top: 0.5rem;
  }
  .footer-item-icon1-select {
    width: 2.5rem;
    height: auto;
    position: absolute;
    top: -0.25rem;
  }
  .footer-item-icon2-select {
    width: 2.9375rem;
    height: auto;
    position: absolute;
    top: -0.4375rem;
  }
  .footer-item-icon3-select {
    width: 2.625rem;
    height: auto;
    position: absolute;
    top: -0.25rem;
  }

  .footer-item-icon2,
  .footer-item-icon3 {
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    top: 0.5rem;
  }
  .footer-item-icon4 {
    width: 38px;
    height: auto;
    position: absolute;
    top: -1px;
  }
  .footer-item-name-select {
    position: absolute;
    bottom: 0.1875rem;
    font-size: 0.6875rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 1rem;
  }
  .footer-item-name {
    position: absolute;
    bottom: 0.1875rem;
    font-size: 0.6875rem;
    font-weight: 500;
    color: #626262;
    line-height: 1rem;
  }
}
</style>
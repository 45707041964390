<template>
  <div v-if="noHeader">
    <div
      v-if="picoReturnHeader"
      class="nomal-header1"
      :class="
        $route.path == '/' || $route.path == '/myCollection' || headerShowBg
          ? 'header-show'
          : 'header-hide'
      "
    >
      <img
        class="back-img"
        @click="goBack()"
        src="../assets/icon/return.png"
      />

      <p class="header-title">{{ title }}</p>
    </div>
    <div
      class="nomal-header2"
      v-if="nomalReturn"
      :style="$route.path == '/aboutUs' || $route.path == '/gashaponRecord' || $route.path == '/recordList' || $route.path == '/rewordList'  ? 'background: transparent;':
       'background: #1c1c1c;'"
    >
      <div
        @click="goBack()"
        v-if="(($route.path == '/donationSelect' && !isSearch) || $route.path !== '/donationSelect') && $route.query.from !== 'pico'"
        class="return-button"
      ></div>
      <img
        v-if="(($route.path == '/donationSelect' && !isSearch) || $route.path !== '/donationSelect') && $route.query.from !== 'pico'"
        class="back-img"
        @click="goBack()"
        src="../assets/icon/return.png"
      />
      <p
        v-if="$route.path !== '/donationSelect'"
        :style="$route.path == '/gashaponRecord' || $route.path == '/recordList' || $route.path == '/rewordList' ? 'color: #1c1c1c;' : ''"
        class="header-title"
      >{{ title }}</p>
      <el-menu
        v-if="$route.path == '/donationSelect' && !isSearch"
        :default-active="selectCollectionTab"
        class="el-menu-demo-header"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item
          index="1"
          class="el-menu-demo-item"
        >
          藏品
          <img
            v-if="selectCollectionTab == 1"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
        <el-menu-item index="2">
          盲盒
          <img
            v-if="selectCollectionTab == 2"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
      </el-menu>
      <div
        v-if="$route.path == '/donationSelect' && isSearch"
        style="width: 100vw;height: 62px;"
        class="row-around"
      >
        <p
          style="color: #e9e9e9;"
          class="donation-search-tip"
          @click="cancerDonationSelect()"
        >取消</p>
        <div class="search-collection">
          <van-field
            v-model="collectionName"
            placeholder="输入名称搜索藏品"
          />
          <img
            style="width:20px;height:auto;"
            @click="setCollectionName()"
            class="search-collection-img"
            src="../assets/icon/search.png"
          />
        </div>
        <p
          style="color: #F3CDB6;"
          class="donation-search-tip"
          @click="confirmDonationSelect()"
        >确认</p>
      </div>

      <a
        v-if="$route.path == '/resetPwd' && isSetPwd"
        class="reset-pwd-a"
        @click="$router.push('/forgetPwd')"
      >忘记密码?</a>
      <a
        v-if="$route.path == '/invitationFriends'"
        class="reset-pwd-a"
        @click="$router.push('/myInvitate')"
      >我的邀请</a>
      <a
        v-if="$route.path == '/integral'"
        class="reset-pwd-a"
        @click="$router.push('/integralDetails')"
      >积分说明</a>

      <a
        v-if="$route.path == '/adressManage' && !adressManageEdit"
        class="reset-pwd-a"
        @click="setAdressManageEdit(true)"
      >管理</a>
      <a
        v-if="$route.path == '/adressManage' && adressManageEdit"
        class="reset-pwd-a"
        @click="setAdressManageEdit(false)"
      >完成</a>

      <a
        v-if="$route.path == '/compositeList'"
        class="reset-pwd-a"
        @click="$router.push('/compositeOrder')"
      >订单</a>
      <img
        v-if="backHome"
        style="width:24px;height:auto;"
        class="reset-pwd-a"
        src="../assets/icon/back-home.png"
        @click="$router.push('/')"
      />
      <img
        v-if="$route.path == '/donationSelect' && !isSearch"
        style="width:20px;height:auto;position: absolute;top: 25px;right: 16px;"
        @click="searchCollection()"
        src="../assets/icon/search.png"
      />
      <img
        @click="showMyCollectionSelect = showMyCollectionSelect ? false : true"
        v-if="$route.path == '/myCollection'"
        style="width: 24px;height: auto;"
        class="reset-pwd-a"
        src="../assets/icon/blindBox/select-icon.png"
      />
      <div
        v-if="showMyCollectionSelect && $route.path == '/myCollection'"
        class="my-collection-select-bg col-center"
      >
        <div
          style="margin-bottom: 22px;"
          class="row-center"
          @click="showMyCollectionSelect = false;$router.push('/compositeRecord')"
        >
          <img
            class="my-collection-select-icon"
            src="../assets/icon/composite/composite.png"
          />
          <p class="my-collection-select-tip">合成记录</p>
        </div>
        <div
          @click="showMyCollectionSelect = false;$router.push('/openBlindBoxRecord')"
          class="row-center"
        >
          <img
            class="my-collection-select-icon"
            src="../assets/icon/blindBox/blindBox.png"
          />
          <p class="my-collection-select-tip">拆盒记录</p>
        </div>
      </div>
    </div>
    <div v-if="floatReturn && !headerShowBg">
      <div
        @click="goBack()"
        class="return-button"
      ></div>
      <img
        @click="goBack()"
        class="return-img"
        src="../assets/icon/return.png"
      />
    </div>
    <el-dialog
      title="确定要离开收银台吗？"
      :visible.sync="authVisible"
      @confirm="cancerOrder()"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top: 2rem"
        class="dialog-footer row-center"
      >
        <el-button @click="authVisible = false">取 消</el-button>
        <div
          @click="cancerOrder()"
          class="confirm-button row-center"
        >确定</div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import { Field } from 'vant';
import { Button,Dialog,Menu,MenuItem } from 'element-ui';
Vue.use(Field)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Menu)
Vue.use(MenuItem)

import api from "../api/index-client";
export default {
  data () {
    return {
      showMyCollectionSelect: false,
      authVisible: false,
      collectionName: '',
    }
  },
  computed: {
    ...mapGetters(["isSetPwd","headerShowBg","isSearch","selectCollectionTab","adressManageEdit"]),
    // 隐私政策、用户协议标题
    title () {
      return this.$route.path == "/goodDetails" &&
        this.$route.query &&
        this.$route.query.isBanner
        ? ""
        : this.$route.path == "/agreementPolicy"
          ? this.$route.query.type == "policy"
            ? "Meta彼岸隐私政策"
            : "Meta彼岸用户协议"
          : this.$route.name;
    },
    // 不显示header
    noHeader () {
      return (
        this.$route.path !== "/share" &&
        this.$route.path !== "/login" &&
        this.$route.path !== '/gl' &&
        this.$route.path !== "/registerShare" &&
        this.$route.path !== "/register" &&
        this.$route.path !== "/download" &&
        this.$route.path !== "/lottery" &&
        this.$route.path !== "/lotteryRule" &&
        this.$route.path !== "/contractCode" &&
        this.$route.path !== "/paySuccess" &&
        this.$route.path !== '/giftReceive' &&
        this.$route.path !== '/gashapon' &&
        this.$route.path !== '/wishingWellList' &&
        this.$route.path !== '/wishingWellDetails'
      );
    },
    // 首页、藏品、我的、探索显示pico返回的操作按钮
    picoReturnHeader () {
      return (
        this.$route.path == "/" ||
        this.$route.path == "/myCollection" ||
        this.$route.path == "/my" ||
        this.$route.path == "/explore" ||
        this.$route.path == '/integralDetails'
      );
    },
    // 正常返回按钮标题显示
    nomalReturn () {
      return (
        this.$route.path == "/set" ||
        this.$route.path == "/myDetail" ||
        this.$route.path == "/orderSelect" ||
        this.$route.path == "/order" ||
        this.$route.path == "/donationOrder" ||
        this.$route.path == "/modifyPhone" ||
        this.$route.path == "/certification" ||
        this.$route.path == "/donation" ||
        this.$route.path == "/set" ||
        this.$route.path == "/resetPwd" ||
        this.$route.path == "/aboutUs" ||
        this.$route.path == "/orderDetail" ||
        this.$route.path == "/buyDetail" ||
        this.$route.path == "/agreementPolicy" ||
        this.$route.path == "/forgetPwd" ||
        this.$route.path == "/invitationFriends" ||
        this.$route.path == "/lotteryList" ||
        this.$route.path == "/compositeList" ||
        this.$route.path == "/compositing" ||
        this.$route.path == "/compositeRecord" ||
        this.$route.path == "/compositeSelectMaterial" ||
        this.$route.path == "/compositeDetail" ||
        this.$route.path == "/compositeOrder" ||
        this.$route.path == "/compositeRecordDetail" ||
        this.$route.path == "/myCollection" ||
        this.$route.path == "/seriesCollection" ||
        this.$route.path == "/myInvitate" ||
        this.$route.path == "/activityDescription" ||
        this.$route.path == "/topList" ||
        this.$route.path == '/donationSelect' ||
        this.$route.path == '/logOff' ||
        this.$route.path == '/logOffPolicy' ||
        this.$route.path == "/openBlindBoxRecord" ||
        this.$route.path == "/blindOrder" ||
        this.$route.path == "/adressManage" ||
        this.$route.path == "/addAdress" ||
        this.$route.path == "/exchangeOrder" ||
        this.$route.path == "/conversion" ||
        this.$route.path == "/conversionCollection" ||
        this.$route.path == "/exchangeDetails" ||
        this.$route.path == "/logistics" ||
        this.$route.path == '/integral' ||
        this.$route.path == '/coupon' ||
        this.headerShowBg
      );
    },
    // 悬浮返回按钮
    floatReturn () {
      return this.$route.path == '/goodDetails' ||
        this.$route.path == '/collection' ||
        this.$route.path == '/blindDetails' ||
        this.$route.path == '/management' ||
        this.$route.path == '/artistDetails' ||
        this.$route.path == '/about' ||
        this.$route.path == '/gashaponRecord' ||
        this.$route.path == '/recordList' ||
        this.$route.path == '/rewordList' ||
        this.$route.path == '/seriesCollectionDetails' ||
        this.$route.path == '/integralExchange' ||
        this.$route.path == '/collectionExchange' ||
        this.$route.path == '/collectionExchangeDetail'
    },
    // 返回首页显示
    backHome () {
      return this.$route.path == '/order' ||
        this.$route.path == '/donationOrder' ||
        this.$route.path == '/compositeOrder' ||
        this.$route.path == '/orderDetail' ||
        this.$route.path == '/blindOrder' ||
        this.$route.path == '/exchangeOrder' ||
        this.$route.path == '/exchangeDetails' ||
        this.$route.path == '/logistics'
    }
  },
  watch: {
    $route (to,from) {
      if (from.path == "/paySuccess" && to.path == "/buyDetail") {
        localStorage.setItem("buyReturn",1);
        this.$router.push("/orderDetail?unique=" + from.query.unique);
      }
      if (from.path == "/orderDetail" && to.path == "/buyDetail") {
        this.$router.push("/");
      }
      if (
        from.path == "/" &&
        to.path == "/buyDetail" &&
        localStorage.getItem("payType") == "alipay"
      ) {
        if (localStorage.getItem("buyDetailReset") == 1) {
          this.$router.push(
            "/orderDetail?unique=" +
            JSON.parse(localStorage.getItem("orderCreate")).orderKey +
            "&type=buy"
          );
        }
      }
      if (
        from.path == "/" &&
        to.path == "/buyDetail" &&
        localStorage.getItem("payType") == "weixin"
      ) {
        // this.$router.go(-2);
      }
      if (from.path == "/buyDetail" && to.path == "/goodDetails") {
        localStorage.setItem("buyReturn",1);
        this.$router.go(1);
        this.authVisible = true;
      }
      if (
        from.path == "/compositing" &&
        to.path != "/compositeSelectMaterial"
      ) {
        this.clearDataCache();
      }
      // 以下页面需要判断在模型加载前是否后退
      // let routeWhiteList = ['/compositeRecordDetail','/compositeDetail','/goodDetails','/management','/seriesCollectionDetails','/artistDetails','/collection']
      // if (routeWhiteList.includes(from.path) && !routeWhiteList.includes(to.path)) {
      //   window.location.reload()
      // }
    },
  },
  methods: {
    goBack () {
      if (this.$route.path === "/buyDetail") {
        this.authVisible = true;
      } else if (this.$route.path === "/agreementPolicy" || this.$route.path === "/logOff") {
        if (Cookies.get('Device') == 'android') {
          // webview内返回上级页面
          var arr1 = JSON.parse(localStorage.getItem('fromUrlList')).filter((i,j) => j < (JSON.parse(localStorage.getItem('fromUrlList')).length - 1))
          this.$router.push(JSON.parse(localStorage.getItem('fromUrlList'))[(JSON.parse(localStorage.getItem('fromUrlList'))).length - 1])
          localStorage.setItem('fromUrlList',JSON.stringify(arr1))
        } else {
          // h5返回上级页面
          if (this.$route.query.from == "h5") {
            this.$router.go(-1);
          } else if (this.$route.query.from == "ios") {
            window.webkit.messageHandlers.goback.postMessage('')
          } else if (this.$route.query.from == "android" || this.$route.query.from == "pico") {
            appBridge.goback()
          } else {
            window.location.href = Cookies.get("nickname")
              ? "uniwebview://closewebview?nickname=" + Cookies.get("nickname")
              : "uniwebview://closewebview";
          }
        }

      } else if (
        this.$route.path === "/orderDetail" &&
        this.$route.query.type == "buy" &&
        this.$route.query.orderselect == 'collection'
      ) {
        this.$router.push("/order");
      } else if (this.$route.path === "/exchangeDetails") {
        this.$router.push("/exchangeOrder");
      } else if (
        this.$route.path === "/orderDetail" &&
        this.$route.query.type == "buy" &&
        this.$route.query.orderselect == 'blind'
      ) {
        this.$router.push("/blindOrder");
      } else if (
        this.$route.path === "/orderDetail" &&
        this.$route.query.type == "donation"
      ) {
        this.$router.push("/donationOrder");
      } else if (
        this.$route.path === "/orderDetail" &&
        this.$route.query.type == "composite"
      ) {
        this.$router.push("/compositeOrder");
      } else if (
        this.$route.path === "/donationOrder" ||
        this.$route.path === "/order" ||
        this.$route.path === "/compositeOrder" ||
        this.$route.path === "/exchangeOrder" ||
        this.$route.path === "/blindOrder"
      ) {
        this.$router.push("/orderSelect");
      } else if (this.$route.path === "/orderSelect") {
        this.$router.push("/my");
      } else if (
        this.$route.path !== "/" &&
        this.$route.path !== "/my" &&
        this.$route.path !== "/myCollection" &&
        this.$route.path !== "/explore"
      ) {
        if (Cookies.get('Device') == 'android') {
          // webview内返回上级页面
          var arr1 = JSON.parse(localStorage.getItem('fromUrlList')).filter((i,j) => j < (JSON.parse(localStorage.getItem('fromUrlList')).length - 1))
          this.$router.push(JSON.parse(localStorage.getItem('fromUrlList'))[(JSON.parse(localStorage.getItem('fromUrlList'))).length - 1])
          localStorage.setItem('fromUrlList',JSON.stringify(arr1))
        } else {
          // h5返回上级页面
          this.$router.go(-1);
        }
      } else if (Cookies.get('Device') == 'android') {
        appBridge.goback()
      } else if (Cookies.get('Device') == 'ios') {
        window.webkit.messageHandlers.goback.postMessage('')
      } else {
        window.location.href = Cookies.get("nickname")
          ? "uniwebview://closewebview?nickname=" + Cookies.get("nickname")
          : "uniwebview://closewebview";
      }
    },
    // tab切换
    handleSelect (key) {
      this.$store.commit('SET_SELECTCOLLECTIONTAB',key)
    },
    setAdressManageEdit (e) {
      this.$store.commit('SET_ADREESSMANAGEEDIT',e)
    },
    searchCollection () {
      this.$store.commit('SHOW_SEARCH')
      this.$store.commit('HIDE_DONATIONSELECT')
      this.collectionName = ""
    },
    cancerDonationSelect () {
      this.$store.commit('HIDE_SEARCH')
      this.collectionName = ""
    },
    confirmDonationSelect () {
      this.$store.commit('SHOW_DONATIONSELECT')
      this.$store.commit('HIDE_SEARCH')
    },
    // 设置藏品搜索名称
    setCollectionName () {
      this.$store.commit('SET_SEARCHCOLLECTIONNAME',this.collectionName)
    },
    cancerOrder () {
      let payFrom;
      payFrom =
        JSON.parse(localStorage.getItem("orderCreate")).payType == "alipay"
          ? "alih5"
          : "weixinh5";
      let userDeductIntegral = localStorage.getItem('userDeductIntegral') ? localStorage.getItem('userDeductIntegral') : 0,
        userCouponsSelectId = localStorage.getItem('userCouponsSelectId') ? localStorage.getItem('userCouponsSelectId') : 0
      api
        .post(
          "order/create/" +
          JSON.parse(localStorage.getItem("orderCreate")).orderKey,
          {
            couponId: userCouponsSelectId,
            payType: JSON.parse(localStorage.getItem("orderCreate")).payType,
            from: payFrom,
            isPay: 0,
            useIntegral: userDeductIntegral
          }
        )
        .then((result) => {
          if (result.data.success) {
            localStorage.removeItem('userDeductIntegral')
            localStorage.removeItem('userCouponsSelectId')
            this.authVisible = false;
            this.$router.replace(
              "/orderDetail?unique=" +
              JSON.parse(localStorage.getItem("orderCreate")).orderKey +
              "&type=buy"
            );
            localStorage.removeItem("orderCreate");
            localStorage.setItem("orderIndex",2);
          } else if (
            result.data.status == 5101 &&
            result.data.msg == "订单已过期,请重新下单"
          ) {
            this.$router.replace("/");
            this.$toast({
              message: result.data.msg,
              icon: require("../assets/icon/toast-error.png"),
            });
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require("../assets/icon/toast-error.png"),
            });
          }
        });
    },
    // 清除缓存
    clearDataCache () {
      sessionStorage.removeItem("selectMaterialIndex");
      sessionStorage.removeItem("value");
      sessionStorage.removeItem("selectMaterialList");
      sessionStorage.removeItem("selectMaterialListMulti");
      sessionStorage.removeItem("selectUnicode");
      sessionStorage.removeItem("selectUnicodeLength");
    },
  },
};
</script>

<style lang="less" scoped>
.header-show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.875rem;
  background: #1c1c1c !important;
  z-index: 20;
}
.header-hide {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.875rem;
  background: transparent !important;
  z-index: 20;
}
.header {
  font-size: 1.125rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.5625rem;
  .back {
    position: absolute;
    left: 0.8125rem;
    img {
      width: 0.625rem;
      height: auto;
      margin-right: 0.5rem;
    }
    p {
      font-size: 0.75rem;
      font-family: lantingheiweight;
      font-weight: 500;
      color: #ffffff;
      line-height: 1.0625rem;
    }
  }
}
.nomal-header1 {
  width: 100vw;
  height: 3.875rem;
  background: #1c1c1c;
  position: fixed;
  top: 0;
  z-index: 201;
  img {
    position: fixed;
    top: 1.8125rem;
    left: 1.875rem;
    width: 0.625rem;
    height: auto;
    margin-right: 0.5rem;
  }
  .header-title {
    width: 100%;
    text-align: center;
    font-size: 1.125rem;
    // font-family: lantingheiweight;
    font-weight: 900;
    color: #ffffff;
    line-height: 1.5625rem;
    padding-top: 1.5rem;
  }
}
.nomal-header2 {
  width: 100vw;
  height: 3.875rem;
  background: #1c1c1c;
  position: fixed;
  top: 0;
  z-index: 9999;
  .back-img {
    position: fixed;
    top: 1.8125rem;
    left: 1.875rem;
    width: 0.625rem;
    height: auto;
    margin-right: 0.5rem;
    z-index: 2002;
  }
  .header-title {
    width: 100%;
    text-align: center;
    font-size: 1.125rem;
    // font-family: lantingheiweight;
    font-weight: bold;
    color: #ffffff;
    line-height: 1.5625rem;
    padding-top: 1.5rem;
  }
  .reset-pwd-a {
    font-size: 14px;
    font-family: lantingheiweight;
    font-weight: 400;
    color: #f3cdb6;
    line-height: 19px;
    position: absolute;
    top: 28px;
    right: 16px;
  }
}
.return-button {
  width: 2.5rem;
  height: 2.5rem;
  background: #000000;
  border-radius: 50%;
  z-index: 2001;
  opacity: 0.2;
  position: absolute;
  top: 1rem;
  left: 1rem;
  filter: alpha(opacity=20);
}
.return-img {
  width: 0.625rem;
  height: auto;
  position: absolute;
  top: 1.75rem;
  left: 2rem;
  z-index: 2002;
}
.search-collection {
  width: 70vw;
  height: 40px;
  position: relative;
  .search-collection-img {
    position: absolute;
    right: 23px;
  }
  .van-cell {
    background: #313131;
    height: 40px;
    border-radius: 23px;
    line-height: 20px;
    background-color: #313131 !important;

    .van-field__control {
      font-size: 14px;
      font-family: lantingheiweight;
      font-weight: 500;
      color: #ffffff;
      line-height: 19px;
      width: 80%;
    }
  }
  .van-cell::after {
    display: none;
  }
}
.search-collection-img {
  width: 20px;
  height: auto;
  position: absolute;
  top: 8px;
  right: 21px;
}
.donation-search-tip {
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  line-height: 20px;
}
.my-collection-select-bg {
  width: 136px;
  height: 109px;
  background-image: url('../assets/icon/blindBox/select-bg.png');
  background-repeat: repeat;
  background-size: cover;
  position: absolute;
  top: 56px;
  right: 23px;
}
.my-collection-select-icon {
  width: 22px;
  height: auto;
  margin-right: 16px;
}
.my-collection-select-tip {
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #e6e6e6;
  line-height: 20px;
}
.el-menu-demo-header {
  width: 100vw;
  margin-top: 10px;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center;
  .el-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1.25rem 0 3vw;
    font-weight: 900 !important;
    color: #666666;
    img {
      width: 0.5rem;
      height: auto;
      position: absolute;
      bottom: 0.375rem !important;
      right: -0.3125rem;
    }
  }
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: #1c1c1c;
  background-color: #1c1c1c !important;
  color: #666666;
}
/deep/.el-menu--horizontal > .el-menu-item.is-active {
  color: #f3cdb6 !important;
}
/deep/.van-field__control {
  font-size: 14px;
  font-family: lantingheiweight;
  font-weight: 500;
  color: #ffffff;
  line-height: 19px;
  width: 80%;
}
</style>
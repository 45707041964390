import axios from 'axios'
import config from './config-client'
import Cookies from 'js-cookie'
import store from "../store";
import QS from 'qs'
axios.defaults.withCredentials = false

var axiosList = []
var routeWhiteList = ['cart/add','order/confirm','order/computed','order/create','gift/transfer','gift/pay','mall/mergetoken/order/payOrder','order/pay']

axios.interceptors.request.use(
  config => {
    if (routeWhiteList.filter(i => config.url.indexOf(i) !== -1).length) {
      const requestInter = setTimeout(() => {
        store.commit("HIDE_APPLOADING")
        store.commit("SHOW_MASKSHOW")
      },3000)
      axiosList.push({requestInter: requestInter,url: config.url})
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    if (axiosList.filter(i => i.url == response.config.url).length) {
      clearTimeout(axiosList.filter(i => i.url == response.config.url)[0].requestInter)
      axiosList = axiosList.filter(i => i.url != response.config.url)
    }
    
    checkDataCode(response)
    return response
  },
  error => {
    checkCode(error.response)
    return Promise.reject(error.response)
  }
)

function checkDataCode(res) {
  if (res && res.data.status === 401) {
    Cookies.remove('Login')
    setTimeout(() => {
      window.location.href = '/'
    }, 2000)
  } else if (res && res.status === 429 || res && res.status === 503) {
    if (routeWhiteList.filter(i => res.config.url.indexOf(i) !== -1).length) {
      store.commit("HIDE_APPLOADING")
      store.commit("SHOW_MASKSHOW")
    }
  } else if (res && res.status == 200) {
  }
  return res
}

function checkCode(res) {
  if (res && res.status === 500) {
    window.location.href = '/500'
  } else if (res && res.status === -400) {
    window.location.href = '/'
  } else if (res && res.status === 401) {
    Cookies.delete('Login')
    setTimeout(() => {
      window.location.href = '/'
    }, 2000)
  } else if (res && res.status === 429 || res && res.status === 503) {
    if (routeWhiteList.filter(i => res.config.url.indexOf(i) !== -1).length) {
      store.commit("HIDE_APPLOADING")
      store.commit("SHOW_MASKSHOW")
    }
  } else if (res && res.status !== 200) {
  }
  return res
}


/**
 * @description: 开发过程中可能api不统一，加个判断如果用户url中带有http，我们认为使用用户自定义url
 * @param {*}url
 * @return {*}boolean
 */
function isUsingHttp(url) {
  return url.indexOf('http') !== -1
}
function isUrlEncoded(headers) {
  return headers['Content-Type'] ? headers['Content-Type'].indexOf('application/x-www-form-urlencoded') !== -1 : false
}
export default {
  post(url, data, options = {}, headers = { 'X-Requested-With': 'XMLHttpRequest', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Method': 'POST,GET,DELETE,OPTIONS','Authorization' : "Bearer " + Cookies.get('Login') }) {
    const opts = Object.assign({}, config, options)
    return axios({
      method: 'post',
      url: isUsingHttp(url) ? url : opts.api + url,
      data: isUrlEncoded(headers) ? QS.stringify(data) : data,
      timeout: opts.timeout,
      headers
    })
  },
  get(url, params = {},options = {}, headers = { 'X-Requested-With': 'XMLHttpRequest', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Method': 'POST,GET,DELETE,OPTIONS','Authorization' : "Bearer " + Cookies.get('Login') }) {
    const opts = Object.assign({}, config, options)
     params.apiSource = 'client'
    return axios({
      method: 'get',
      url: isUsingHttp(url) ? url : opts.api + url,
      params,
      timeout: opts.timeout,
      headers
    })
  },
  delete(url, params = {}, options = {}, headers = { 'X-Requested-With': 'XMLHttpRequest', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Method': 'POST,GET,DELETE,OPTIONS','Authorization' : "Bearer " + Cookies.get('Login') }) {
    const opts = Object.assign({}, config, options)
    params.apiSource = 'client'
    return axios({
      method: 'delete',
      url: isUsingHttp(url) ? url : opts.api + url,
      params,
      timeout: opts.timeout,
      headers
    })
  }
}
